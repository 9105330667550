import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  COMMON_AUTH_HEADER,
  ORDER_SERVICE,
  ORDER_SERVICE_TOKEN,
  PSP_AUTH_TOKEN_KEY,
} from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { RemoteGetOrderDetails } from "../../../data/usecases/remote-get-order-details";
import { GetOrderDetails } from "../../../domain/usages/get-order-details";
import { PatientOrderDetailsType } from "../../../domain/models/patient-order-details-type";

interface GetOrderDetailsSliceType {
  handleGetOrderDetails: Function;
  getOrderDetailsLoading: boolean;
  patientOrderDetails: PatientOrderDetailsType;
}

const initialStates = {
  getOrderDetailsLoading: false,
  patientOrderDetails: {} as PatientOrderDetailsType,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useOrderDetailsSlice: StateCreator<GetOrderDetailsSliceType> = (
  set
) => ({
  ...initialStates,
  handleGetOrderDetails: async (id: string) => {
    let token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${ORDER_SERVICE_TOKEN}`,
    });

    const remoteGetOrderDetails: GetOrderDetails = new RemoteGetOrderDetails(
      `${ORDER_SERVICE}${Endpoints.GET_ORDER_DETAILS}`,
      axiosHttpClient
    );
    set(() => ({ getOrderDetailsLoading: true, patientOrderDetails: {} as PatientOrderDetailsType }));
    let result = await remoteGetOrderDetails.get(id);
    if (result.success) {
      set(() => ({
        getOrderDetailsLoading: false,
        patientOrderDetails: result.order,
      }));
    } else {
      set(() => ({ getOrderDetailsLoading: true }));
      Swal.fire("Something went wrong", "", "error");
    }
  },
});
