import { GetReadLink } from "../../domain/usages/get-read-link";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteGetFulfillmentReadLink implements GetReadLink {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(uuid: string, params: GetReadLink.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", uuid),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
      authHeadersViaCaptcha: false
    });
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
