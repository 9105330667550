import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import moment from "moment";

type Props = {
  openChangePatientStatusModal: boolean;
  handleCloseConfirmationModal: Function;
  handleSuccessCallback: Function;
  message: string;
  loading: boolean;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ChangePatientStatusModal: React.FC<Props> = ({
  openChangePatientStatusModal,
  handleCloseConfirmationModal,
  handleSuccessCallback,
  message,
  loading,
}) => {
  const [statusDate, setStatusDate] = useState<Dayjs | null>(null);
  const [reason, setReason] = useState<string>("");

  return (
    <Modal
      open={openChangePatientStatusModal}
      onClose={() => handleCloseConfirmationModal()}
    >
      <Box sx={style}>
        <Typography>{message}</Typography>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="ll"
            value={statusDate}
            disableFuture
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
              },
            }}
            onChange={(newValue) => setStatusDate(newValue)}
          />
        </LocalizationProvider>
        <TextField
          rows={4}
          multiline
          placeholder="Enter valid reason..."
          fullWidth
          size="small"
          aria-multiline
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />

        <Stack
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
          justifyContent={"end"}
        >
          {reason && statusDate && (
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={() =>
                handleSuccessCallback(
                  reason,
                  moment(statusDate.toString()).format("YYYY-MM-DD")
                )
              }
            >
              <Typography textTransform={"none"}>Confirm</Typography>
            </LoadingButton>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleCloseConfirmationModal()}
          >
            <Typography textTransform={"none"}>Cancel</Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ChangePatientStatusModal;
