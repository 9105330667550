import {
  Typography,
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
  CircularProgress,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MenuItem from "@mui/material/MenuItem";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useSendToCFAStore } from "../../../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import {
  CFAInvoiceDetails,
  CFAOrderedItemDetails,
  SendToCFADetails,
} from "../../../../../domain/models/send-to-cfa";
import { format } from "date-fns";
import { Constant } from "../../../../../constants/constant";
import {
  PatientAddressType,
  PatientContactType,
} from "../../../../../domain/models/personal-details-type";
import { UOMType } from "../../../../../domain/models/program-product-option-type";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import EditAddressDetailsModal from "../personal-details/EditAddressDetailsModal";
import {
  filterAddress,
  getPatientMobileContact,
  getPatientWhatsappContact,
} from "../../../../../domain/Utils";
import { SupplierListType } from "../../../../../domain/models/supplier-list-type";
import { Program } from "../../../../../constants/enums/program";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import dayjs from "dayjs";
interface Contact {
  fullName: string;
  mobile: string;
  altMobile: string;
}
interface FormattedData {
  createdAt: string;
  fullName: string;
  contacts: Contact[];
}

const SendtoCFA: React.FC = () => {
  const { selectedCard } = useEnrollmentMainStore();
  const {
    programProductOptions,
    fetchProgramProductOptions,
    supplierList,
    makeOrder,
    fetchSupplierOptions,
    supplierListLoading,
  } = useSendToCFAStore();
  const { selectedPatientPersonalDetails } = usePersonalDetailStore();

  const { handleEdit } = useProgramDetailStore();
  const [openAddAddressModal, setOpenAddAddressModal] =
    useState<boolean>(false);

  const [orderDetail, setOrderDetail] = useState<CFAOrderedItemDetails[]>([]);
  const [invoiceDetail, setInvoiceDetail] = useState<CFAInvoiceDetails[]>([]);

  const [selectedPrimaryAddress, setSelectedPrimaryAddress] = useState<
    boolean | null
  >(null);
  const [selectedSecondaryAddress, setSelectedSecondaryAddress] = useState<
    boolean | null
  >(null);

  const { handleSubmit, control, setValue, watch } = useForm<SendToCFADetails>({
    mode: "onChange",
  });

  const [openCalendars, setOpenCalendars] = useState([false]);

  const toggleCalendar = (index: number, value: boolean) => {
    setOpenCalendars((prev) =>
      prev.map((item, idx) => (idx === index ? value : item))
    );
  };
  const primaryAddress: PatientAddressType[] =
    selectedPatientPersonalDetails.addresses.filter((mobile) => {
      return mobile.type === "primary" && mobile.status === "active";
    });

  const secondaryAddress: PatientAddressType[] =
    selectedPatientPersonalDetails.addresses.filter((mobile) => {
      return mobile.type === "secondary" && mobile.status === "active";
    });

  const filteredPrimaryAddress: PatientAddressType =
    primaryAddress.length > 0
      ? Object.entries(primaryAddress[primaryAddress.length - 1]).reduce(
          (acc: any, [key, value]) => {
            if (
              value !== "" &&
              value !== null &&
              key !== "created_at" &&
              key !== "updated_at"
            ) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        )
      : {};

  const handleOpenAddAddressModal = (value: boolean) => {
    setOpenAddAddressModal(value);
  };

  const filteredSecondaryAddress: PatientAddressType =
    secondaryAddress.length > 0
      ? Object.entries(secondaryAddress[secondaryAddress.length - 1]).reduce(
          (acc: any, [key, value]) => {
            if (
              value !== "" &&
              value !== null &&
              key !== "created_at" &&
              key !== "updated_at"
            ) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        )
      : {};

  const getStockistDataById = (id: string) => {
    const stockistData = supplierList.find((item) => item.supplier_uuid == id);
    if (stockistData) {
      return {
        supplier_uuid: stockistData.supplier_uuid,
        name: stockistData.name,
        pan_no: stockistData.pan_no,
        gst_no: stockistData.gst_no,
        dl_no: stockistData.dl_no,
        supplier_type: "Stockist",
        priority: 1,
      };
    }
    return {
      supplier_uuid: "",
      name: "",
      pan_no: "",
      gst_no: "",
      dl_no: "",
      supplier_type: "",
      priority: 1,
    };
  };

  const getSelectedProduct = (id: string) => {
    const itemData = programProductOptions.find((item) => item._id == id);
    if (itemData) {
      return itemData.uoms;
    } else {
      return [];
    }
  };

  const selectedItemDetailsType = (id: string) => {
    const itemData = programProductOptions.find((item) => item._id == id);
    if (itemData) {
      return {
        brand_name: itemData.brand.name,
        product_name: itemData.name,
        brand_uuid: itemData.brand.brand,
        product_uuid: itemData._id,
        organization_code: selectedCard.org_code,
        division_code: selectedCard.division_code,
        ga_headquarter_code: "GAASCD09009A",
        headquarter_name: null,
        ga_brick_code: null,
        igst: itemData.gstPercentage.igst,
        cgst: itemData.gstPercentage.cgst,
        sgst: itemData.gstPercentage.sgst,
        brick_name: null,
        suppliers: getStockistDataById(watch("select_cfa"))
          ? [getStockistDataById(watch("select_cfa"))]
          : null,
      };
    }
    return {
      brand_name: "",
      product_name: "",
      brand_uuid: "",
      product_uuid: "",
      organization_code: "",
      division_code: "",
      ga_headquarter_code: "",
      headquarter_name: "",
      ga_brick_code: "",
      brick_name: "",
      suppliers: null,
    };
  };

  useEffect(() => {
    setValue("select_cfa", "");
    setValue("addressline", "");
    setValue("landmark", "");
    setValue("city", "");
    setValue("expected_delivery_date", null);
    setValue("patient_cycle_date", null);
    setValue(
      "primaryaddress",
      primaryAddress.length > 0 &&
        Object.values(primaryAddress[primaryAddress.length - 1]) != null
        ? filterAddress(
            primaryAddress[primaryAddress.length - 1].line,
            primaryAddress[primaryAddress.length - 1].landmark,
            primaryAddress[primaryAddress.length - 1].area,
            primaryAddress[primaryAddress.length - 1].city,
            primaryAddress[primaryAddress.length - 1].district,
            primaryAddress[primaryAddress.length - 1].state,
            primaryAddress[primaryAddress.length - 1].pincode,
            "India"
          )
        : "-"
    );
    setValue(
      "secondaryaddress",
      secondaryAddress.length > 0 &&
        secondaryAddress[secondaryAddress.length - 1] != null
        ? filterAddress(
            secondaryAddress[secondaryAddress.length - 1].line,
            secondaryAddress[secondaryAddress.length - 1].landmark,
            secondaryAddress[secondaryAddress.length - 1].area,
            secondaryAddress[secondaryAddress.length - 1].city,
            secondaryAddress[secondaryAddress.length - 1].district,
            secondaryAddress[secondaryAddress.length - 1].state,
            secondaryAddress[secondaryAddress.length - 1].pincode,
            "India"
          )
        : "-"
    );
    orderDetail.splice(0, orderDetail.length);
    invoiceDetail.splice(0, invoiceDetail.length);
    addOrder();
    addInvoiceDetail();
    fetchProgramProductOptions(selectedCard.program.product_group_id);
    let params = {
      organization_code: selectedCard.org_code,
      division_code: selectedCard.division_code,
      supplier_type: "stockist",
      length: 1000,
    };
    fetchSupplierOptions(params);
  }, [selectedCard.id]);

  const addInvoiceDetail = () => {
    openCalendars.push(false);
    invoiceDetail.push({
      invoice_date: "",
      product_name: "",
      invoice_no: "",
      invoice_quantity: "",
      comments: "",
      stockist_name: "",
    });
    setOpenCalendars([...openCalendars]);
    setInvoiceDetail([...invoiceDetail]);
  };
  const cancelInvoiceDetails = (index: number) => {
    openCalendars.slice(index, 1);
    invoiceDetail.splice(index, 1);
    setOpenCalendars([...openCalendars]);
    setInvoiceDetail([...invoiceDetail]);
  };

  const addOrder = () => {
    orderDetail.push({
      brand_name: "",
      product_name: "",
      uom: "",
      quantity: "",
      unit_price: "",
      brand_uuid: "",
      product_uuid: "",
      uom_uuid: "",
      organization_code: "",
      division_code: "",
      ga_headquarter_code: "",
      headquarter_name: "",
      ga_brick_code: "",
      brick_name: "",
      suppliers: null,
      igst: 0,
      cgst: 0,
      sgst: 0,
    });

    setOrderDetail([...orderDetail]);
  };
  const cancelOrder = (index: number) => {
    orderDetail.splice(index, 1);
    setOrderDetail([...orderDetail]);
  };

  const handleChangeInvoiceInput = (
    index: number,
    name: string,
    value: string
  ) => {
    const values: CFAInvoiceDetails[] = [...invoiceDetail];
    if (name === "invoiceNumber") {
      values[index].invoice_no = value;
      setInvoiceDetail(values);
    } else if (name === "invoiceDate") {
      values[index].invoice_date = value;
      setInvoiceDetail(values);
    } else if (name === "invoiceQty") {
      values[index].invoice_quantity = value;
      setInvoiceDetail(values);
    } else if (name === "productName") {
      values[index].product_name = value;
      setInvoiceDetail(values);
    } else if (name === "comments") {
      values[index].comments = value;
      setInvoiceDetail(values);
    } else if (name === "stockistName") {
      values[index].stockist_name = value;
      setInvoiceDetail(values);
    } else {
      setInvoiceDetail(values);
    }
  };

  const handleChangeInput = (index: number, name: string, value: string) => {
    const values: CFAOrderedItemDetails[] = [...orderDetail];
    programProductOptions?.map((option: any) => {
      if (option._id == value && name == "name") {
        values[index].unit_price = "";
        values[index].quantity = "";

        values[index].cgst = selectedItemDetailsType(value).cgst ?? 0;
        values[index].igst = selectedItemDetailsType(value).igst ?? 0;
        values[index].sgst = selectedItemDetailsType(value).sgst ?? 0;
        values[index].brand_name = selectedItemDetailsType(value).brand_name;
        values[index].brand_uuid = selectedItemDetailsType(value).brand_uuid;
        values[index].brick_name = selectedItemDetailsType(value).brick_name;
        values[index].division_code =
          selectedItemDetailsType(value).division_code;
        values[index].ga_brick_code =
          selectedItemDetailsType(value).ga_brick_code;
        values[index].ga_headquarter_code =
          selectedItemDetailsType(value).ga_headquarter_code;
        values[index].headquarter_name =
          selectedItemDetailsType(value).headquarter_name;
        values[index].organization_code =
          selectedItemDetailsType(value).organization_code;
        values[index].product_name =
          selectedItemDetailsType(value).product_name;
        values[index].product_uuid =
          selectedItemDetailsType(value).product_uuid;
        values[index].suppliers = selectedItemDetailsType(value).suppliers;
        setOrderDetail(values);
      }
    });
    if (name === "quantity") {
      values[index].quantity = value;
      setOrderDetail(values);
    }
    if (name === "uom") {
      getSelectedProduct(values[index].product_uuid).map((options: UOMType) => {
        if (options._id == value) {
          values[index].uom = options.type;
          values[index].uom_uuid = options._id;
          values[index].unit_price =
            options.latestPrice.priceToCustomer.price.toString();
        }
      });

      setOrderDetail(values);
    }
  };

  const handleClick = (data: SendToCFADetails) => {
    let selectedCard = useEnrollmentMainStore.getState().selectedCard;
    let selectedAddress = selectedPrimaryAddress
      ? filteredPrimaryAddress
      : selectedSecondaryAddress
      ? filteredSecondaryAddress
      : ({} as PatientAddressType);

    let payload = {
      app_code: "PSP",
      customer_type: "patient",
      created_by: "1",
      name: selectedPatientPersonalDetails.fullName,
      mobile: getPatientMobileContact(selectedPatientPersonalDetails.contacts),
      whatsapp_number: getPatientWhatsappContact(
        selectedPatientPersonalDetails.contacts
      ),
      order_type:
        selectedCard.program_id.toString() == Program.EISAI
          ? "FOC"
          : "COMMERCIAL",
      program_code: selectedCard.program.code,
      program_name: selectedCard.program.name,
      creator_name: "Patient Support Admin",
      customer_uuid: selectedCard.patient_uuid,
      approval_required: 0,
      meta_data:
        selectedCard.program_id.toString() == Program.EISAI
          ? {
              doctor_name: selectedCard.doctor_full_name,
              invoice_details: invoiceDetail,
            }
          : {
              doctor_name: selectedCard.doctor_full_name,
            },
      address: {
        pin_code: selectedAddress.pincode,
        line: selectedAddress.line,
        landmark: selectedAddress.landmark,
        city: selectedAddress.city,
        district: selectedAddress.district,
        state: selectedAddress.state,
        country: "India",
        lat: "0",
        long: "0",
      },
      items: orderDetail,
      stakeholders: [
        {
          code: "PSPADMIN",
          name: "PSP ADMIN",
          mobile: "7330609466",
          whatsapp_number: "7330609466",
          email: "pspadmin@connectod.com",
          role: "PSPADMIN",
          app_code: "PSP",
        },
      ],
    };

    makeOrder(payload);
  };

  const calculateProductAmount = (orderData: CFAOrderedItemDetails) => {
    let sum = 0;

    sum =
      parseFloat(orderData.unit_price) * parseInt(orderData.quantity) +
      (parseFloat(orderData.unit_price) * orderData.igst) / 100;
    return sum ? sum : 0;
  };

  const calculateTotalOrderedAmount = () => {
    let totalAmount = 0;
    if (orderDetail.length > 0) {
      for (let i = 0; i < orderDetail.length; i++) {
        totalAmount =
          totalAmount +
          parseFloat(orderDetail[i].unit_price) *
            parseInt(orderDetail[i].quantity) +
          (parseFloat(orderDetail[i].unit_price) * orderDetail[i].igst) / 100;
      }
      return totalAmount;
    } else {
      return 0;
    }
  };

  return (
    <>
      {supplierListLoading ? (
        <Stack height={"50vh"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress color="secondary" />
        </Stack>
      ) : (
        <Stack
          overflow={"scroll"}
          height={"60vh"}
          paddingBottom={20}
          sx={{
            "::-webkit-scrollbar": {
              display: "block",
              width: "5px",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            position={"fixed"}
            width={"41%"}
            bgcolor={"white"}
            zIndex={10}
            paddingBottom={2}
          >
            <Typography variant="body1" gutterBottom>
              CFA Details
            </Typography>
            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                disabled={
                  orderDetail.some(
                    (item) => item.product_name === "" || item.quantity === ""
                  ) ||
                  (selectedCard.program_id == 3 &&
                    invoiceDetail.some(
                      (item) =>
                        item.invoice_no === "" ||
                        item.invoice_date === "" ||
                        item.invoice_quantity === "" ||
                        item.product_name === "" ||
                        item.stockist_name === "" ||
                        item.comments == ""
                    )) ||
                  calculateTotalOrderedAmount() == null ||
                  (!selectedPrimaryAddress && !selectedSecondaryAddress)
                }
                sx={{ bgcolor: "#3F51B7", borderRadius: "30px" }}
                onClick={handleSubmit(handleClick)}
              >
                SEND TO CFA
              </Button>
              <Button
                variant="text"
                sx={{ color: "#3F51B7" }}
                onClick={() => handleEdit(0)}
              >
                CLOSE
              </Button>
            </Stack>
          </Stack>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={11}>
              <Typography variant="caption" display="block" gutterBottom>
                Select CFA
              </Typography>

              <Controller
                name="select_cfa"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    onChange={(
                      event: any,
                      newValue: SupplierListType | null
                    ) => {
                      onChange(newValue ? newValue.supplier_uuid : null);
                    }}
                    size="small"
                    options={supplierList}
                    getOptionLabel={(option) =>
                      `${option.name} - (${option.district}, ${option.state}, ${option.pin_code})`
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.supplier_uuid}>
                        {option.name} - ({option.district}, {option.state},{" "}
                        {option.pin_code})
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        error={error && true}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Stack direction="row" alignItems="center" marginTop="20px">
              <Typography variant="body1">Select Address</Typography>
              <Tooltip title={"click to add address"}>
                <IconButton onClick={() => handleOpenAddAddressModal(true)}>
                  <AddLocationAltIcon color="secondary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Grid container spacing={2} sx={{ marginTop: "0px" }}>
              <Grid item xs={6}>
                <Stack direction="row" gap={1}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Primary Address
                  </Typography>
                  <input
                    onClick={() => {
                      setSelectedPrimaryAddress(true);
                      setSelectedSecondaryAddress(null);
                    }}
                    disabled={primaryAddress.length == 0}
                    type="radio"
                    name="default_address"
                    value="Default_Address"
                    style={{
                      marginBottom: "8px",
                      marginLeft: "6px",
                    }}
                  />
                </Stack>
                <Controller
                  name="primaryaddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      id="primaryaddress"
                      variant="standard"
                      fullWidth
                      inputProps={{ readOnly: true }}
                      size="small"
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      multiline
                      rows={2}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: Constant.FIELD_REQUIRED },
                  }}
                />
              </Grid>
              {filteredSecondaryAddress && (
                <Grid item xs={6}>
                  <Stack direction="row" gap={1}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Secondary Address
                    </Typography>
                    <input
                      onClick={() => {
                        setSelectedSecondaryAddress(true);
                        setSelectedPrimaryAddress(null);
                      }}
                      disabled={secondaryAddress.length == 0}
                      type="radio"
                      name="default_address"
                      value="Default_Address"
                      style={{
                        marginBottom: "8px",
                        marginLeft: "6px",
                      }}
                    />
                  </Stack>
                  <Controller
                    name="secondaryaddress"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id="secondaryaddress"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                        fullWidth
                        size="small"
                        value={value}
                        error={error && true}
                        helperText={error?.message}
                        onChange={onChange}
                        multiline
                        rows={2}
                        sx={{
                          borderRadius: "3.5px",
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1} padding={0} margin={0}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className="orderItemsTitle">{`Order Items 
                (Approx Amount: ${
                  calculateTotalOrderedAmount()
                    ? calculateTotalOrderedAmount().toFixed(2)
                    : 0
                })`}</Typography>
                  <Button variant="text" onClick={() => addOrder()}>
                    Add More
                  </Button>
                </Stack>
              </Grid>
              {orderDetail.map((data, index: number) => {
                return (
                  <>
                    <Tooltip title={orderDetail[index].product_name}>
                      <Grid item xs={4}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Product:
                        </Typography>

                        <TextField
                          id="product"
                          disabled={!watch("select_cfa")}
                          variant="outlined"
                          select
                          size="small"
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeInput(
                              index,
                              "name",
                              event.target.value
                            );
                          }}
                        >
                          {programProductOptions?.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Tooltip>

                    <Grid item xs={2}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        UOM:
                      </Typography>

                      <TextField
                        id="uom"
                        variant="outlined"
                        select
                        disabled={data.product_uuid ? false : true}
                        size="small"
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChangeInput(index, "uom", event.target.value);
                        }}
                      >
                        {getSelectedProduct(data.product_uuid).length > 0 &&
                          getSelectedProduct(data.product_uuid).map(
                            (option) => (
                              <MenuItem key={option._id} value={option._id}>
                                <Stack>
                                  {option.type == "unit" ? (
                                    <Typography>{option.type}</Typography>
                                  ) : (
                                    <Typography>
                                      {option.type} ({option.units} units){" "}
                                    </Typography>
                                  )}
                                </Stack>
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Unit Price:
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={
                          orderDetail[index].unit_price
                            ? parseFloat(orderDetail[index].unit_price).toFixed(
                                2
                              )
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={1.2}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Quantity:
                      </Typography>

                      <TextField
                        size="small"
                        id="quantity"
                        disabled={!watch("select_cfa")}
                        variant="outlined"
                        value={data.quantity}
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChangeInput(
                            index,
                            "quantity",
                            event.target.value
                          );
                        }}
                        rows={2}
                        sx={{
                          borderRadius: "3.5px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        fontSize={"11px"}
                        marginTop={0.4}
                        display="block"
                        gutterBottom
                      >
                        Amount (incl tax):
                      </Typography>

                      <TextField
                        size="small"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={calculateProductAmount(
                          orderDetail[index]
                        ).toFixed(2)}
                      />
                    </Grid>
                    {index > 0 && (
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        padding={0.5}
                        marginTop={1}
                      >
                        <RemoveCircleOutlineIcon
                          onClick={() => cancelOrder(index)}
                          fontSize="small"
                          color="secondary"
                        />
                      </Stack>
                    )}
                  </>
                );
              })}
            </Grid>
            {selectedCard.program_id.toString() == Program.EISAI && (
              <Grid container spacing={1} padding={0} margin={0}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className="orderItemsTitle">{`Invoice Details 
                `}</Typography>
                    <Button variant="text" onClick={() => addInvoiceDetail()}>
                      Add More
                    </Button>
                  </Stack>
                </Grid>
                {invoiceDetail.map((data, index: number) => {
                  return (
                    <>
                      <Tooltip title={invoiceDetail[index].product_name}>
                        <Grid item xs={3}>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            Invoice No:
                          </Typography>

                          <TextField
                            id="invoiceNumber"
                            disabled={!watch("select_cfa")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={data.invoice_no}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeInvoiceInput(
                                index,
                                "invoiceNumber",
                                event.target.value
                              );
                            }}
                          />
                        </Grid>
                      </Tooltip>

                      <Grid item xs={3.5}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Invoice Date:
                        </Typography>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                                onClick: () => toggleCalendar(index, true),
                              },
                            }}
                            closeOnSelect={false}
                            disableFuture
                            views={["year", "day"]}
                            format="ll"
                            open={openCalendars[index]}
                            onClose={() => toggleCalendar(index, false)}
                            onOpen={() => toggleCalendar(index, true)}
                            onChange={(newValue) => {
                              handleChangeInvoiceInput(
                                index,
                                "invoiceDate",
                                newValue
                                  ? moment(dayjs(newValue).toDate()).format(
                                      "YYYY-MM-DD"
                                    )
                                  : ""
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={1.5}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Qty:
                        </Typography>

                        <TextField
                          size="small"
                          id="quantity"
                          disabled={!watch("select_cfa")}
                          variant="outlined"
                          value={data.invoice_quantity}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeInvoiceInput(
                              index,
                              "invoiceQty",
                              event.target.value
                            );
                          }}
                          rows={2}
                          sx={{
                            borderRadius: "3.5px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={3.5}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Product Name:
                        </Typography>

                        <TextField
                          id="product"
                          disabled={!watch("select_cfa")}
                          variant="outlined"
                          select
                          size="small"
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeInvoiceInput(
                              index,
                              "productName",
                              event.target.value
                            );
                          }}
                        >
                          {programProductOptions?.map((option) => (
                            <MenuItem key={option._id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={11}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Stockist Name:
                        </Typography>

                        <TextField
                          size="small"
                          disabled={!watch("select_cfa")}
                          variant="outlined"
                          value={data.stockist_name}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeInvoiceInput(
                              index,
                              "stockistName",
                              event.target.value
                            );
                          }}
                          rows={2}
                          sx={{
                            borderRadius: "3.5px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Comments
                        </Typography>

                        <TextField
                          rows={4}
                          multiline
                          size="small"
                          id="comments"
                          disabled={!watch("select_cfa")}
                          variant="outlined"
                          value={data.comments}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeInvoiceInput(
                              index,
                              "comments",
                              event.target.value
                            );
                          }}
                          sx={{
                            borderRadius: "3.5px",
                          }}
                        />
                      </Grid>

                      {index > 0 && (
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          padding={0.5}
                          marginTop={1}
                        >
                          <RemoveCircleOutlineIcon
                            onClick={() => cancelInvoiceDetails(index)}
                            fontSize="small"
                            color="secondary"
                          />
                        </Stack>
                      )}
                    </>
                  );
                })}
              </Grid>
            )}
          </Grid>

          {openAddAddressModal && (
            <EditAddressDetailsModal
              addressType={""}
              open={openAddAddressModal}
              handleClose={() => handleOpenAddAddressModal(false)}
              selectedPatientPersonalDetails={selectedPatientPersonalDetails}
            />
          )}
        </Stack>
      )}
    </>
  );
};

export default SendtoCFA;
