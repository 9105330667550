import { create } from "zustand";
import { useUploadFileSlice } from "./uploadFileSlice";
import { useUploadFilestoDBSlice } from "./uploadFileToDB";
import { useFulfillmentGetReadLinkSlice } from "./GetReadLinkSlice";

interface LoginStoreType
  extends ReturnType<typeof useUploadFileSlice>,
    ReturnType<typeof useUploadFilestoDBSlice>,
    ReturnType<typeof useFulfillmentGetReadLinkSlice> {}

export const useFulfillmentStore = create<LoginStoreType>()((...data) => ({
  ...useUploadFileSlice(...data),
  ...useUploadFilestoDBSlice(...data),
  ...useFulfillmentGetReadLinkSlice(...data)
}));
