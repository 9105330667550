import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteFetchProgramDocuments } from "../../../../data/usecases/remote-fetch-program-documents";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { FetchProgramDocuments } from "../../../../domain/usages/fetch-program-documents";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";

interface FetchProgramDocumentSliceType {
  getProgramDocs: Function;
  handleRefreshUrl: Function
  url: {uuid:string, url:string}[];
}

const initialStates = {
  url: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchProgramDocumentSlice: StateCreator<
  FetchProgramDocumentSliceType
> = (set, get) => ({
  ...initialStates,
  handleRefreshUrl: ()=>{
    set(() => ({ url:[] }));
  },
  getProgramDocs: async (uuid: string, captcha: string) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token??"" });
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captcha??"" });

    const remoteFetchProgramDocuments: FetchProgramDocuments =
      new RemoteFetchProgramDocuments(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.GET_PROGRAM_DOCUMENT}`,
        axiosHttpClient
      );

    if (uuid) {
      let docsurl = await remoteFetchProgramDocuments.fetch({ uuid });
      if (docsurl.status) {
        let result={
          uuid: uuid,
          url: docsurl.data.url
        }
        set(() => ({ url:[...get().url, result] }));
      }
    }
  },
});
