import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDocumentStore } from "../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import useRecaptcha from "../hooks/reCaptcha";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../base";

const uploadCard = {
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "95%",
};

type Props = {
  uuid: string | null;
  title: string;
  id: number;
  file: any;
  onFileSelect: Function;
  reset: () => void;
  isLoading: boolean;
  error?: boolean;
  helperText?: string;
  message?: string;
  isConsent?: boolean;
  cameraInputRef: any;
  galleryInputRef: any;
  handleOpenModal: Function;
  refName?: string;
  documentGroupType: string;
};

const UploadFileCard: React.FC<Props> = ({
  uuid,
  title,
  id,
  file,
  onFileSelect,
  reset,
  isLoading = false,
  error = false,
  helperText = "",
  message = "",
  isConsent = false,
  cameraInputRef,
  handleOpenModal,
  galleryInputRef,
  refName = "",
  documentGroupType,
}) => {
  const { getProgramDocs, url } = useDocumentStore();

  const {
    captchaToken: getProgramDocsToken,
    executeRecaptcha: executeRecaptchaGetProgramDocs,
    isCaptchaLoading,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "getProgramDocs");

  useEffect(() => {
    if (getProgramDocsToken) {
      getProgramDocs(uuid, getProgramDocsToken);
    }
  }, [getProgramDocsToken]);

  const getDocumentUrl = useCallback(async () => {
    if (uuid && !file) {
      executeRecaptchaGetProgramDocs();
    }
  }, [uuid, file]);

  useEffect(() => {
    if (uuid) getDocumentUrl();
  }, [uuid]);

  const findUrl = (imageUuid: string) => {
    const imageURL = url.find((item) => item.uuid == imageUuid);
    return imageURL?.url;
  };

  if (url.length > 0 && uuid) {
    return (
      <Card variant="outlined">
        <Stack
          flexDirection={"row"}
          spacing={1}
          width={"100%"}
          alignItems={"center"}
        >
          <img src={findUrl(uuid)} alt={title} width="10%" height={"10%"} />
          <Typography
            padding={2}
            variant="subtitle2"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {title}
          </Typography>
        </Stack>
      </Card>
    );
  }
  if (file)
    return (
      <UploadedFileCard
        file={file}
        reset={reset}
        isConsent={isConsent}
        docType={documentGroupType}
      />
    );
  else
    return (
      <FormControl error={error}>
        <FormHelperText sx={{ margin: 0 }}>
          <Typography variant="subtitle2">{message} </Typography>
        </FormHelperText>
        <Card variant={"outlined"}>
          <div style={uploadCard}>
            <Typography variant="subtitle2">{title}</Typography>

            <input
              ref={cameraInputRef}
              capture="environment"
              accept="image/*"
              type="file"
              id={"consent" + id.toFixed()}
              style={{ display: "none" }}
              onChange={(e: any) => {
                const file = e.target.files[0];
                if (file) {
                  onFileSelect(file);
                }
                e.target.value = "";
              }}
            />

            <input
              ref={galleryInputRef}
              accept="image/*"
              type="file"
              id={"consent" + id.toFixed()}
              style={{ display: "none" }}
              onChange={(e: any) => {
                const file = e.target.files[0];
                if (file) {
                  onFileSelect(file);
                }
                e.target.value = "";
              }}
            />
            <Stack>
              <Button
                sx={{ color: "#fff" }}
                variant="contained"
                color="secondary"
                disabled={isConsent}
                id={"consent" + id.toFixed()}
                component="span"
                onClick={() =>
                  handleOpenModal(true, refName, documentGroupType)
                }
              >
                {isLoading ? "Uploading..." : "Capture"}
              </Button>
            </Stack>
          </div>
        </Card>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
};

type UploadedProps = {
  file: File;
  reset: () => void;
  isConsent?: boolean;
  docType: string;
};

//TODO:: instead of hard coding the width and height it should be from style file.
const UploadedFileCard: React.FC<UploadedProps> = ({
  file,
  reset,
  isConsent = false,
  docType,
}) => {
  return (
    <Card variant={"outlined"}>
      <div style={uploadCard}>
        {file && <img src={URL.createObjectURL(file)} width="20%" />}
        <Stack width={isConsent ? "80%" : "50%"}>
          <Typography
            variant="subtitle2"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {docType}
          </Typography>
        </Stack>
        {!isConsent && (
          <Button
            sx={{ color: "#fff" }}
            variant="contained"
            onClick={() => reset()}
            color="secondary"
            component="span"
          >
            Reset
          </Button>
        )}
      </div>
    </Card>
  );
};

export default UploadFileCard;
