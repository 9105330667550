import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  COMMON_AUTH_HEADER,
  COMMON_PATIENT_SERVICE_API_URL,
  PATIENT_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { usePersonalDetailStore } from "./personalDetailsStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";

import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";
import { UpdatePatientStatus } from "../../../../domain/usages/update-patient-status";
import { RemoteUpdatePatientStatus } from "../../../../data/usecases/remote-update-patient-status";

interface UpdatePatientStatusSliceType {
  updatePatientStatus: Function;
  updatePatientStatusLoading: boolean;

}
const initialStates = {
  updatePatientStatusLoading: false
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdatePatientStatusSlice: StateCreator<
UpdatePatientStatusSliceType
> = (set, get) => ({
  ...initialStates,
  updatePatientStatus: async (
    id: string,

    payload: UpdatePatientStatus.Params,
    successCallback: Function
  
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
 

    const remoteUpdatePatientStatus: UpdatePatientStatus =
      new RemoteUpdatePatientStatus(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.UPADTE_ENROLMENT_STATUS}`,
        axiosHttpClient
      );
      set(() => ({
        updatePatientStatusLoading: true
      }));
    // usePersonalDetailStore.getState().handleLoading(true);
    let result = await remoteUpdatePatientStatus.update(
    id, payload
    );
    if (result.success) {
      set(() => ({
        updatePatientStatusLoading: false
      }));
      successCallback()
      // usePersonalDetailStore.getState().handleLoading(false);
      usePersonalDetailStore
        .getState()
        .getPatient(
          useEnrollmentMainStore.getState().selectedCard.patient_uuid
        );
        useEnrollmentMainStore
        .getState()
        .fetchEnrollments(1, useEnrollmentMainStore.getState().filter, false);
      Swal.fire("Patient Status Updated successfully", "", "success");
    } else {
      successCallback()
      set(() => ({
        updatePatientStatusLoading: false
      }));
      usePersonalDetailStore.getState().handleLoading(false);
      if (result.errors && result.errors.length > 0) {
        Swal.fire(result.errors[0].message, "", "error");
      } else {
        Swal.fire(result.message, "", "error");
      }
    }
  },
});
