import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  DOCTOR_SERVICE_API_URL,
  AUTH_HEADER,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  CreateAppointment,
  DocumentUpload,
} from "../../../domain/usages/appointment-module/create-appointment";
import { RemoteCreateAppointment } from "../../../data/usecases/appointment-module/remote-create-appointment";
import { CreateAppointmentResponse } from "../../../domain/models/appointment-module/create-appointment-response";
import { useAppointmentDetailsStore } from "../patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import { Constant } from "../../../constants/constant";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface CreateAppointmentSliceInterface {
  createAppointment: Function;
  createAppointmentLoading: boolean;
  addImagesToUpload: Function;
  imagesToUploads: DocumentUpload[];
}

const initialStates = {
  createAppointmentLoading: false,
  imagesToUploads: [] as DocumentUpload[],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useCreateAppointmentlice: StateCreator<
  CreateAppointmentSliceInterface
> = (set, get) => ({
  ...initialStates,
  createAppointment: async (payload: CreateAppointment.Params) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteCreateAppointment = new RemoteCreateAppointment(
      `${DOCTOR_SERVICE_API_URL}${Endpoints.CREATE_APPOINTMENT}`,
      axiosHttpClient
    );

    let finalPayload;

    if (get().imagesToUploads.length > 0) {
      finalPayload = { ...payload, documents: get().imagesToUploads };
    } else {
      finalPayload = payload;
    }

    set(() => ({ createAppointmentLoading: true }));
    let result: CreateAppointmentResponse =
      await remoteCreateAppointment.create(finalPayload);

    if (result) {
      if (result.success) {
        set(() => ({ createAppointmentLoading: false }));
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        useAppointmentDetailsStore.getState().openCreateAppointment = false;
        useAppointmentDetailsStore
          .getState()
          .fetchAppointments(
            1,
            false,
            useAppointmentDetailsStore.getState().appointmentFilter
          );
        get().imagesToUploads = [];
      } else {
        Swal.fire("Appointment creation failed.", "", "error");
        get().imagesToUploads = [];
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
      get().imagesToUploads = [];
    }
  },
  addImagesToUpload: (uploadedImage: DocumentUpload) => {
    set((state) => ({
      imagesToUploads: [...state.imagesToUploads, uploadedImage],
    }));
  },
});
