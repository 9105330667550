import { StateCreator } from "zustand";
import { FetchState } from "../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { RemoteUploadFileToDB } from "../../data/usecases/fullfilment/remote-upload-file-to-DB";
import Endpoints from "../../domain/endpoints";
import {
  COMMON_AUTH_HEADER,
  FULFILLMENT_SERVICE_API_URL,
  FULFILLMENT_SERVICE_TOKEN,
} from "../../base";
import Swal from "sweetalert2";

type FileUpload = {
  file: any;
  uuid?: string;
  success: boolean;
  url?: string;
};

interface UploadFilestoDBSliceInterface {
  uploadFiles: Function;
  fetchUploadFilesState: FetchState;
}

const axiosHttpClient = AxiosHttpClient.getInstance();

const initialStates = {
  fetchUploadFilesState: FetchState.DEFAULT,
};

export const useUploadFilestoDBSlice: StateCreator<
  UploadFilestoDBSliceInterface
> = (set, get) => ({
  ...initialStates,
  uploadFiles: async (
    order_id: number,
    sub_order_id: number,
    type: string,
    uploadedBy: number,
    uploaderName: string,
    fileUpload: FileUpload,
   
  ) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });
    const remoteUploadFilestoDB = new RemoteUploadFileToDB(
      `${FULFILLMENT_SERVICE_API_URL}${Endpoints.UPLOAD_FILES_TO_DB}`,
      axiosHttpClient
    );
    let payload;

    if (fileUpload.uuid) {
      payload = {
        document_type: type,
        file_uuid: fileUpload.uuid,
        uploaded_by: uploadedBy,
        uploader_name: uploaderName
      };

      try {
        set(() => ({ fetchUploadFilesState: FetchState.LOADING }));
        let result = await remoteUploadFilestoDB.upload(
          order_id,
          sub_order_id,
          payload
        );

        if (result) {
          if (result.success) {
            set(() => ({
              fetchUploadFilesState: FetchState.SUCCESS,
            }));

            Swal.fire({
              icon: "success",
              title: "Document uploaded sucessfully",
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          } else {
            if (result.message) {
              Swal.fire(result.message, "", "error");
            } else {
            }
            set(() => ({
              fetchUploadFilesState: FetchState.SUCCESS,
            }));
          }
        } else {
          set(() => ({ fetchUploadFilesState: FetchState.ERROR }));
        }
      } catch (err) {
        set(() => ({ fetchUploadFilesState: FetchState.ERROR }));
      }
    }
  },
});
