import { CancelAppointmentResponse } from "../../../domain/models/appointment-module/cancel-appointment-response";
import { CancelAppointment } from "../../../domain/usages/appointment-module/cancel-appointment";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteCancelAppointment implements CancelAppointment {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async cancel(
    appointment_id: number,
    params: CancelAppointment.Params
  ): Promise<CancelAppointmentResponse> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":appointment_id", appointment_id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
      authHeadersViaCaptcha: true
    });

    return httpResponse.data;
  }
}
