import React from "react";
import LoginPage from "./presentation/pages/LoginPage";
import StockistPage from "./presentation/pages/StockistPage";
import SupportAdminPage from "./presentation/pages/SupportAdminPage";
import { ProgramEnrolmentFactory } from "./main/ProgramEnrolmentFactory";
import { MobileAppointmentModulePage } from "./presentation/pages/MobileAppointmentModulePage";

export const pageRoutes = {
  loginPage: "/login",
  adminPage: "/adminPage",
  orderService: "/orderService",
  consentForm: "/patients/:id/enrolments/:token",
  enrolmentForm: "/enrolments",
  mobileAppointment: "/appointment/:appointment_id",
};

export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  children?: AppRoute[];
}

let indexRoutes: AppRoute[] = [
  {
    path: pageRoutes.loginPage,
    name: "Login Page",
    component: LoginPage,
  } as AppRoute,
  {
    path: pageRoutes.adminPage,
    name: "Admin Page",
    component: SupportAdminPage,
  } as AppRoute,
  {
    path: pageRoutes.orderService,
    name: "Order Page",
    component: StockistPage,
  } as AppRoute,
 
  {
    path: pageRoutes.enrolmentForm,
    name: "Program Enrolment Form Page",
    component: ProgramEnrolmentFactory,
  } as AppRoute,
  {
    path: pageRoutes.mobileAppointment,
    name: "Appointment Module(Mobile)",
    component: MobileAppointmentModulePage,
  } as AppRoute,
];

export default indexRoutes;
