import { create } from "zustand";
import { useUploadFileSlice } from "./UploadFileSlice";
import { useDoctorServiceGetReadLinkSlice } from "./getReadUrlSlice";
import { usefetchDoctorsSlice } from "./fetchDoctors";

interface DoctorServiceInterface
  extends ReturnType<typeof useUploadFileSlice>,
    ReturnType<typeof useDoctorServiceGetReadLinkSlice>,
    ReturnType<typeof usefetchDoctorsSlice> {}

export const useDoctorServiceStore =
  create<DoctorServiceInterface>()((...common) => ({
    ...useUploadFileSlice(...common),
    ...useDoctorServiceGetReadLinkSlice(...common),
    ...usefetchDoctorsSlice(...common)
  }));
