import { Stack, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDoctorServiceStore } from "../../store/doctor-service/DoctorServiceStore";
import ImagePreviewModal from "./modals/ImagePreviewModal";
import { FetchState } from "../../domain/models/fetch-state-type";
import useRecaptcha from "../hooks/reCaptcha";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../base";

type Props = {
  uuid: string;
  title: string;
};

const ImageCard: React.FC<Props> = ({ uuid, title }) => {
  const [localImageUrl, setLocalImageUrl] = useState<string>("");
  const [showImagePreview, setShowImagePreview] = useState(false);
  const {
    captchaToken: fetchImageToken,
    executeRecaptcha: executeRecaptchaFetchImage,
    isCaptchaLoading,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchImage");

  const { getImageFromDoctorService, fetchImageState } =
    useDoctorServiceStore();

  const handleClose = () => {
    setShowImagePreview(false);
  };

  const fetchImage = async () => {
    const imageUrl = await getImageFromDoctorService(uuid, fetchImageToken);
    setLocalImageUrl(imageUrl);
  };

  useEffect(() => {
    if (uuid) {
      executeRecaptchaFetchImage();
    }
  }, [uuid]);

  useEffect(() => {
    if (fetchImageToken) {
      fetchImage();
    }
  }, [fetchImageToken]);

  return (
    <>
      <Stack
        width={"100px"}
        onClick={() => localImageUrl && setShowImagePreview(true)}
        alignItems="center"
        justifyContent="center"
        sx={{ cursor: localImageUrl ? "pointer" : "default" }}
      >
        {fetchImageState === FetchState.LOADING ? (
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            width={100}
            height={100}
          >
            <CircularProgress size={24} />
          </Stack>
        ) : fetchImageState === FetchState.ERROR ? (
          <Typography fontSize={"12px"} color="error">
            Failed to load image
          </Typography>
        ) : (
          <img
            width={"100px"}
            height={"100px"}
            src={localImageUrl}
            alt={title}
          />
        )}
        <Typography
          textAlign={"center"}
          fontSize={"12px"}
          mt={1}
          width={"100px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {title}
        </Typography>
      </Stack>
      {showImagePreview && localImageUrl && (
        <ImagePreviewModal
          imageLink={localImageUrl}
          handleClose={handleClose}
          showImagePreview={showImagePreview}
        />
      )}
    </>
  );
};

export default ImageCard;
