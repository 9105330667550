import {
  Typography,
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
  CircularProgress,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useSendToCFAStore } from "../../../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import { Constant } from "../../../../../constants/constant";
import { PatientAddressType } from "../../../../../domain/models/personal-details-type";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import EditAddressDetailsModal from "../personal-details/EditAddressDetailsModal";
import {
  filterAddress,
  getPatientEmailContact,
  getPatientMobileContact,
  getPatientWhatsappContact,
} from "../../../../../domain/Utils";
import { SupplierListType } from "../../../../../domain/models/supplier-list-type";
import { useSendToLabStore } from "../../../../../store/adminPageStore/patientDetailsStore/sendToLabStore/sendToLabStore";
import {
  LabOrderedItemDetails,
  SendToLabDetails,
} from "../../../../../domain/models/send-to-lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";

interface SampleCollectionDataType {
  sampleCollectionType: string;
  sampleCollectionDateAndTime: Dayjs | null;
  sampleCollectionLocation: string;
  methodOfTest: string;
  remarks: string;
}

const LabOrders: React.FC = () => {
  const { selectedCard } = useEnrollmentMainStore();
  const { supplierList, makeOrder, fetchSupplierOptions, supplierListLoading } =
    useSendToCFAStore();

  const { fetchLabReportOptions, labReportOptions } = useSendToLabStore();
  const { selectedPatientPersonalDetails } = usePersonalDetailStore();
  const [sampleCollectionDate, setSampleCollectionDate] =
    useState<boolean>(false);

  const { handleEdit } = useProgramDetailStore();
  const [openAddAddressModal, setOpenAddAddressModal] =
    useState<boolean>(false);

  const [orderDetail, setOrderDetail] = useState<LabOrderedItemDetails>(
    {} as LabOrderedItemDetails
  );
  const [sampleCollectionDetails, setSampleCollectionDetails] =
    useState<SampleCollectionDataType>({} as SampleCollectionDataType);
  const [selectedPrimaryAddress, setSelectedPrimaryAddress] = useState<
    boolean | null
  >(null);
  const [selectedSecondaryAddress, setSelectedSecondaryAddress] = useState<
    boolean | null
  >(null);

  const { handleSubmit, control, setValue, watch } = useForm<SendToLabDetails>({
    mode: "onChange",
  });
  const primaryAddress: PatientAddressType[] =
    selectedPatientPersonalDetails.addresses.filter((mobile) => {
      return mobile.type === "primary" && mobile.status === "active";
    });

  const secondaryAddress: PatientAddressType[] =
    selectedPatientPersonalDetails.addresses.filter((mobile) => {
      return mobile.type === "secondary" && mobile.status === "active";
    });

  const filteredPrimaryAddress: PatientAddressType =
    primaryAddress.length > 0
      ? Object.entries(primaryAddress[primaryAddress.length - 1]).reduce(
          (acc: any, [key, value]) => {
            if (
              value !== "" &&
              value !== null &&
              key !== "created_at" &&
              key !== "updated_at"
            ) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        )
      : {};

  const handleOpenAddAddressModal = (value: boolean) => {
    setOpenAddAddressModal(value);
  };

  const filteredSecondaryAddress: PatientAddressType =
    secondaryAddress.length > 0
      ? Object.entries(secondaryAddress[secondaryAddress.length - 1]).reduce(
          (acc: any, [key, value]) => {
            if (
              value !== "" &&
              value !== null &&
              key !== "created_at" &&
              key !== "updated_at"
            ) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        )
      : {};

  const getStockistDataById = (id: string) => {
    const stockistData = supplierList.find((item) => item.supplier_uuid == id);
    if (stockistData) {
      return {
        supplier_uuid: stockistData.supplier_uuid,
        name: stockistData.name,
        pan_no: stockistData.pan_no,
        gst_no: stockistData.gst_no,
        dl_no: stockistData.dl_no,
        supplier_type: "Stockist",
        priority: 1,
      };
    }
    return {
      supplier_uuid: "",
      name: "",
      pan_no: "",
      gst_no: "",
      dl_no: "",
      supplier_type: "",
      priority: 1,
    };
  };

  const sampleCollectionType = [
    {
      id: 1,
      label: "Home Collection",
    },
    { id: 2, label: "Sample pickup" },
    {
      id: 3,
      label: "Center visit",
    },
  ];

  const selectedItemDetailsType = (id: number) => {
    const itemData = labReportOptions.find((item) => item.id == id);
    if (itemData) {
      return {
        brand_name: itemData.category,
        product_name: itemData.method,
        method_of_test: itemData.method_code,
        brand_uuid: itemData.category_uuid,
        product_uuid: itemData.method_uuid,
        organization_code: selectedCard.org_code,
        division_code: selectedCard.division_code,
        ga_headquarter_code: "GAASCD09009A",
        headquarter_name: null,
        ga_brick_code: null,
        igst: itemData.igst,
        cgst: itemData.cgst,
        sgst: itemData.sgst,
        brick_name: null,
        unit_price: itemData.price_to_lab,
        suppliers: getStockistDataById(watch("selectLab"))
          ? [getStockistDataById(watch("selectLab"))]
          : null,
      };
    }
    return {
      brand_name: "",
      product_name: "",
      brand_uuid: "",
      product_uuid: "",
      organization_code: "",
      division_code: "",
      ga_headquarter_code: "",
      headquarter_name: "",
      ga_brick_code: "",
      brick_name: "",
      suppliers: null,
      unit_price: "",
      method_of_test: "",
    };
  };

  useEffect(() => {
    setSampleCollectionDetails({
      ...sampleCollectionDetails,
      sampleCollectionDateAndTime: null,
    });
    setValue("selectLab", "");
    setValue("addressline", "");
    setValue("landmark", "");
    setValue("city", "");

    setValue(
      "primaryaddress",
      primaryAddress.length > 0 &&
        Object.values(primaryAddress[primaryAddress.length - 1]) != null
        ? filterAddress(
            primaryAddress[primaryAddress.length - 1].line,
            primaryAddress[primaryAddress.length - 1].landmark,
            primaryAddress[primaryAddress.length - 1].area,
            primaryAddress[primaryAddress.length - 1].city,
            primaryAddress[primaryAddress.length - 1].district,
            primaryAddress[primaryAddress.length - 1].state,
            primaryAddress[primaryAddress.length - 1].pincode,
            "India"
          )
        : "-"
    );
    setValue(
      "secondaryaddress",
      secondaryAddress.length > 0 &&
        secondaryAddress[secondaryAddress.length - 1] != null
        ? filterAddress(
            secondaryAddress[secondaryAddress.length - 1].line,
            secondaryAddress[secondaryAddress.length - 1].landmark,
            secondaryAddress[secondaryAddress.length - 1].area,
            secondaryAddress[secondaryAddress.length - 1].city,
            secondaryAddress[secondaryAddress.length - 1].district,
            secondaryAddress[secondaryAddress.length - 1].state,
            secondaryAddress[secondaryAddress.length - 1].pincode,
            "India"
          )
        : "-"
    );
    setOrderDetail({} as LabOrderedItemDetails);

    let params = {
      organization_code: selectedCard.org_code,
      division_code: selectedCard.division_code,
      supplier_type: "diagnostic_center",
    };
    fetchSupplierOptions(params);
  }, [selectedCard.id]);

  useEffect(() => {
    if (watch("selectLab")) {
      fetchLabReportOptions(watch("selectLab"));
    }
  }, [watch("selectLab")]);

  const handleSampleCollectionData = (name: string, value: any) => {
    const temp: SampleCollectionDataType = {} as SampleCollectionDataType;

    if (name == "sampleCollectionDateTime") {
      temp.sampleCollectionDateAndTime = value;
      setSampleCollectionDetails({
        ...sampleCollectionDetails,
        sampleCollectionDateAndTime: value,
      });
    } else if (name == "sampleCollectionType") {
      temp.sampleCollectionType = value;
      setSampleCollectionDetails({
        ...sampleCollectionDetails,
        sampleCollectionType: value,
      });
    } else if (name == "remarks") {
      setSampleCollectionDetails({
        ...sampleCollectionDetails,
        remarks: value,
      });
    }
  };

  const handleChangeInput = (name: string, value: number) => {
    const values: LabOrderedItemDetails = {} as LabOrderedItemDetails;

    labReportOptions.map((option) => {
      if (option.id == value && name == "name") {
        values.cgst = selectedItemDetailsType(value).cgst ?? 0;
        values.igst = selectedItemDetailsType(value).igst ?? 0;
        values.sgst = selectedItemDetailsType(value).sgst ?? 0;
        values.brand_name = selectedItemDetailsType(value).brand_name;
        values.brand_uuid = selectedItemDetailsType(value).brand_uuid;
        values.brick_name = selectedItemDetailsType(value).brick_name;
        values.division_code = selectedItemDetailsType(value).division_code;
        values.ga_brick_code = selectedItemDetailsType(value).ga_brick_code;
        values.ga_headquarter_code =
          selectedItemDetailsType(value).ga_headquarter_code;
        values.headquarter_name =
          selectedItemDetailsType(value).headquarter_name;
        values.organization_code =
          selectedItemDetailsType(value).organization_code;
        values.product_name = selectedItemDetailsType(value).product_name;
        values.product_uuid = selectedItemDetailsType(value).product_uuid;
        values.suppliers = selectedItemDetailsType(value).suppliers;
        values.quantity = "1";
        values.uom = "unit";

        values.uom_uuid = selectedItemDetailsType(value).product_uuid;
        values.unit_price =
          selectedItemDetailsType(value).unit_price.toString();
      }
      setOrderDetail(values);
      setSampleCollectionDetails({
        ...sampleCollectionDetails,
        methodOfTest: selectedItemDetailsType(value).method_of_test,
      });
    });
  };
  const handleClick = () => {
    let selectedAddress = selectedPrimaryAddress
      ? filteredPrimaryAddress
      : selectedSecondaryAddress
      ? filteredSecondaryAddress
      : ({} as PatientAddressType);

    let payload = {
      app_code: "PSP",
      customer_type: "patient",
      created_by: "1",
      name: selectedPatientPersonalDetails.fullName,
      mobile: getPatientMobileContact(selectedPatientPersonalDetails.contacts),
      whatsapp_number: getPatientWhatsappContact(
        selectedPatientPersonalDetails.contacts
      ),
      creator_name: "Patient Support Admin",
      customer_uuid: selectedCard.patient_uuid,
      order_type: "LAB_ORDER",
      program_code: selectedCard.program.code,
      program_name: selectedCard.program.name,
      meta_data: {
        gender: selectedCard.gender,
        email: getPatientEmailContact(selectedPatientPersonalDetails.contacts),
        age: selectedPatientPersonalDetails.age,
        doctor_name: selectedCard.doctor_full_name,
        sample_collection_type: sampleCollectionDetails.sampleCollectionType,
        sample_collection_date_and_time: dayjs(
          sampleCollectionDetails.sampleCollectionDateAndTime
        )
          .locale("en")
          .format("LLL"),
        sample_collection_location: selectedPrimaryAddress
          ? filterAddress(
              filteredPrimaryAddress.line,
              filteredPrimaryAddress.landmark,
              filteredPrimaryAddress.area,
              filteredPrimaryAddress.city,
              filteredPrimaryAddress.district,
              filteredPrimaryAddress.state,
              filteredPrimaryAddress.pincode,
              "India"
            )
          : selectedSecondaryAddress
          ? filterAddress(
              filteredSecondaryAddress.line,
              filteredSecondaryAddress.landmark,
              filteredSecondaryAddress.area,
              filteredSecondaryAddress.city,
              filteredSecondaryAddress.district,
              filteredSecondaryAddress.state,
              filteredSecondaryAddress.pincode,
              "India"
            )
          : ({} as PatientAddressType),
        method_of_test: sampleCollectionDetails.methodOfTest
          ? sampleCollectionDetails.methodOfTest
          : "",
        remarks: sampleCollectionDetails.remarks
          ? sampleCollectionDetails.remarks
          : null,
      },
      address: {
        pin_code: selectedAddress.pincode,
        line: selectedAddress.line,
        landmark: selectedAddress.landmark,
        city: selectedAddress.city,
        district: selectedAddress.district,
        state: selectedAddress.state,
        country: "India",
        lat: "0",
        long: "0",
      },
      items: [orderDetail],
      stakeholders: [
        {
          code: "PSPADMIN",
          name: "PSP ADMIN",
          mobile: "7330609466",
          whatsapp_number: "7330609466",
          email: "pspadmin@connectod.com",
          role: "PSPADMIN",
          app_code: "PSP",
        },
      ],
    };

    makeOrder(payload);
  };

  const calculateProductAmount = (orderData: LabOrderedItemDetails) => {
    let sum = 0;

    sum =
      parseFloat(orderData.unit_price) +
      (parseFloat(orderData.unit_price) * orderData.igst) / 100;

    return sum;
  };

  return (
    <>
      {supplierListLoading ? (
        <Stack height={"50vh"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress color="secondary" />
        </Stack>
      ) : (
        <Stack overflow={"scroll"} height={"50vh"}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            position={"fixed"}
            width={"41%"}
            bgcolor={"white"}
            zIndex={10}
            paddingBottom={2}
          >
            <Typography variant="body1" gutterBottom>
              Lab Details
            </Typography>
            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                disabled={
                  (!selectedPrimaryAddress && !selectedSecondaryAddress) ||
                  !sampleCollectionDetails.sampleCollectionDateAndTime ||
                  !sampleCollectionDetails.sampleCollectionType
                }
                sx={{ bgcolor: "#3F51B7", borderRadius: "30px" }}
                onClick={handleSubmit(handleClick)}
              >
                SEND TO LAB
              </Button>
              <Button
                variant="text"
                sx={{ color: "#3F51B7" }}
                onClick={() => handleEdit(0)}
              >
                CLOSE
              </Button>
            </Stack>
          </Stack>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={11}>
              <Typography variant="caption" display="block" gutterBottom>
                Select Lab
              </Typography>

              <Controller
                name="selectLab"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    onChange={(
                      event: any,
                      newValue: SupplierListType | null
                    ) => {
                      onChange(newValue ? newValue.supplier_uuid : null);
                    }}
                    size="small"
                    options={supplierList}
                    getOptionLabel={(option) =>
                      `${option.name} - (${option.district}, ${option.state}, ${option.pin_code})`
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.supplier_uuid}>
                        {option.name} - ({option.district}, {option.state},{" "}
                        {option.pin_code})
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        error={error && true}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          </Grid>

          <Stack direction="row" alignItems="center" marginTop="20px">
            <Typography variant="body1">Select Address</Typography>
            <Tooltip title={"click to add address"}>
              <IconButton onClick={() => handleOpenAddAddressModal(true)}>
                <AddLocationAltIcon color="secondary" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
          <Grid container spacing={2} sx={{ marginTop: "0px" }}>
            <Grid item xs={6}>
              <Stack direction="row" gap={1}>
                <Typography variant="caption" display="block" gutterBottom>
                  Primary Address
                </Typography>
                <input
                  onClick={() => {
                    setSelectedPrimaryAddress(true);
                    setSelectedSecondaryAddress(null);
                  }}
                  disabled={primaryAddress.length == 0}
                  type="radio"
                  name="default_address"
                  value="Default_Address"
                  style={{
                    marginBottom: "8px",
                    marginLeft: "6px",
                  }}
                />
              </Stack>
              <Controller
                name="primaryaddress"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="primaryaddress"
                    variant="standard"
                    fullWidth
                    inputProps={{ readOnly: true }}
                    size="small"
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                    multiline
                    rows={2}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
            {filteredSecondaryAddress && (
              <Grid item xs={6}>
                <Stack direction="row" gap={1}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Secondary Address
                  </Typography>
                  <input
                    onClick={() => {
                      setSelectedSecondaryAddress(true);
                      setSelectedPrimaryAddress(null);
                    }}
                    disabled={secondaryAddress.length == 0}
                    type="radio"
                    name="default_address"
                    value="Default_Address"
                    style={{
                      marginBottom: "8px",
                      marginLeft: "6px",
                    }}
                  />
                </Stack>
                <Controller
                  name="secondaryaddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      id="secondaryaddress"
                      variant="standard"
                      inputProps={{ readOnly: true }}
                      fullWidth
                      size="small"
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      multiline
                      rows={2}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>

          <Stack flexDirection={"row"} gap={2} marginTop={2}>
            <Stack width={"50%"}>
              <Typography variant="caption" gutterBottom>
                Select Test:
              </Typography>

              <TextField
                disabled={!watch("selectLab")}
                variant="outlined"
                select
                size="small"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeInput("name", parseInt(event.target.value));
                }}
              >
                {labReportOptions.length > 0 &&
                  labReportOptions.map((option) => (
                    <MenuItem key={option.lab_uuid} value={option.id}>
                      {option.method}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
            <Stack justifyContent={"center"}>
              <Typography variant="caption" gutterBottom>
                Sample Collection Date and Time:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      onClick: () => setSampleCollectionDate(true),
                    },
                  }}
                  closeOnSelect={false}
                  views={["year", "day", "hours", "minutes"]}
                  format="ll, hh:mm A"
                  open={sampleCollectionDate}
                  onClose={() => {
                    setSampleCollectionDate(false);
                  }}
                  onOpen={() => {
                    setSampleCollectionDate(true);
                  }}
                  disablePast
                  onChange={(newValue) => {
                    handleSampleCollectionData(
                      "sampleCollectionDateTime",
                      newValue
                    );
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
          <Stack flexDirection={"row"} gap={2} marginTop={2}>
            <Stack width={"40%"}>
              <Typography variant="caption" gutterBottom>
                Sample Collection Type:
              </Typography>

              <TextField
                id="product"
                disabled={!watch("selectLab")}
                variant="outlined"
                select
                size="small"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleSampleCollectionData(
                    "sampleCollectionType",
                    event.target.value
                  );
                }}
              >
                {sampleCollectionType.length > 0 &&
                  sampleCollectionType.map((option) => (
                    <MenuItem key={option.id} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
            <Stack>
              <Typography variant="caption" display="block" gutterBottom>
                Unit Price:
              </Typography>
              <TextField
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                value={
                  orderDetail.unit_price
                    ? parseFloat(orderDetail.unit_price).toFixed(2)
                    : ""
                }
              />
            </Stack>
            <Stack>
              <Typography
                fontSize={"11px"}
                marginTop={0.4}
                display="block"
                gutterBottom
              >
                Amount (incl tax):
              </Typography>

              <TextField
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                value={
                  calculateProductAmount(orderDetail)
                    ? calculateProductAmount(orderDetail).toFixed(2)
                    : 0
                }
              />
            </Stack>
          </Stack>
          <Stack marginTop={2}>
            <Typography
              fontSize={"11px"}
              marginTop={0.4}
              display="block"
              gutterBottom
            >
              Remarks:
            </Typography>
            <TextField
              multiline
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSampleCollectionData("remarks", event.target.value);
              }}
            />
          </Stack>

          {openAddAddressModal && (
            <EditAddressDetailsModal
              addressType={""}
              open={openAddAddressModal}
              handleClose={() => handleOpenAddAddressModal(false)}
              selectedPatientPersonalDetails={selectedPatientPersonalDetails}
            />
          )}
        </Stack>
      )}
    </>
  );
};

export default LabOrders;
