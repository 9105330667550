import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  DOCTOR_SERVICE_API_URL,
  AUTH_HEADER,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { useAppointmentDetailsStore } from "../patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Constant } from "../../../constants/constant";
import { RemoteEditAppointment } from "../../../data/usecases/appointment-module/remote-edit-appointment";
import { EditAppointmentResponse } from "../../../domain/models/appointment-module/edit-appointment-response";
import { EditAppointment } from "../../../domain/usages/appointment-module/edit-appointment";
import moment from "moment";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface EditAppointmentSliceInterface {
  editAppointment: Function;
  editAppointmentLoading: FetchState;
}

const initialStates = {
  editAppointmentLoading: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useEditAppointmentSlice: StateCreator<
  EditAppointmentSliceInterface
> = (set, get) => ({
  ...initialStates,
  editAppointment: async (
    appointment_id: number,
    payload: EditAppointment.Params
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteeditAppointment = new RemoteEditAppointment(
      `${DOCTOR_SERVICE_API_URL}${Endpoints.EDIT_APPOINTMENT}`,
      axiosHttpClient
    );

    let finalPayload;

    if (useAppointmentDetailsStore.getState().imagesToUploads.length > 0) {
      finalPayload = {
        ...payload,
        documents: useAppointmentDetailsStore.getState().imagesToUploads,
      };
    } else {
      finalPayload = payload;
    }

    set(() => ({ editAppointmentLoading: FetchState.LOADING }));
    let result: EditAppointmentResponse = await remoteeditAppointment.edit(
      appointment_id,
      finalPayload
    );

    if (result) {
      if (result.success) {
        set(() => ({ editAppointmentLoading: FetchState.SUCCESS }));
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
        useAppointmentDetailsStore
          .getState()
          .fetchAppointments(
            1,
            false,
            useAppointmentDetailsStore.getState().appointmentFilter
          );
        useAppointmentDetailsStore.getState().showAllAppointments &&
          useAppointmentDetailsStore
            .getState()
            .fetchCalendarCount(
              moment(
                useAppointmentDetailsStore.getState().appointmentDate.toString()
              ).format(Constant.YEAR_MONTH_FORMAT),
              useAppointmentDetailsStore.getState().appointmentFilter.status
            );
        useAppointmentDetailsStore.getState().imagesToUploads = [];
      } else {
        Swal.fire("Editing Appointment Failed.", "", "error");
        useAppointmentDetailsStore.getState().imagesToUploads = [];
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
      useAppointmentDetailsStore.getState().imagesToUploads = [];
    }
  },
});
