import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteGenerateSignedUrlForDocumentUpload } from "../../../../data/usecases/remote-generate-signed-url-for-document-upload";
import Endpoints from "../../../../domain/endpoints";
import { UploadFileType } from "../../../../domain/models/upload-file-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { GenerateSignedUrlForDocumentUpload } from "../../../../domain/usages/generate-signed-url-for-document-upload";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";
import Swal from "sweetalert2";

interface GenerateSignedUrlType {
  generateSignedUrl: Function;
  showUpdateDocumentModal: boolean;
  showAddNewDocumentModal: boolean;
  handleCloseDocumentModal: Function;
  handleAddNewDocumentModal: Function;
  handleUpdateDocumentModal: Function;
  toBeUploaded: boolean;
}

const initialStates = {
  toBeUploaded: false,
  showAddNewDocumentModal: false,
  showUpdateDocumentModal: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGenerateSignedUrlSlice: StateCreator<GenerateSignedUrlType> = (
  set
) => ({
  ...initialStates,
  generateSignedUrl: async (
    imageFile: UploadFileType,
    document: string,
    documentGroup: string
  ) => {
    set(() => ({ toBeUploaded: true }));
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token??"" });
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: "" });

    const remoteGenerateSignedUrlForDocumentUpload: GenerateSignedUrlForDocumentUpload =
      new RemoteGenerateSignedUrlForDocumentUpload(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.GENERATE_SIGNED_URL_FOR_DOCUMENT_UPLOAD}`,
        axiosHttpClient
      );

    let payload:GenerateSignedUrlForDocumentUpload.Params= {
      enrolment_id:useEnrollmentMainStore.getState().selectedCard.id,
      document_type: document,
      document_group: documentGroup,
      name: imageFile.name,
      size: imageFile.size,
      type: imageFile.type,
    };
    let result = await remoteGenerateSignedUrlForDocumentUpload.generate(
      payload
    );
    if (result.success) {
      set(() => ({
        showAddNewDocumentModal: false,
        showUpdateDocumentModal: false,
      }));
      return result
    } else {
      set(() => ({
        showAddNewDocumentModal: false,
        showUpdateDocumentModal: false,
      }));
      Swal.fire("Failed to upload document", "", "error")
      throw new Error("Couldn't generate url.");
    }
  },
  handleCloseDocumentModal: () => {
    set(() => ({
      showAddNewDocumentModal: false,
      showUpdateDocumentModal: false,
    }));
  },
  handleAddNewDocumentModal: () => {
    set(() => ({ showAddNewDocumentModal: true }));
  },
  handleUpdateDocumentModal: () => {
    set(() => ({ showUpdateDocumentModal: true }));
  },
});
