import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  DOCTOR_SERVICE_API_URL,
  AUTH_HEADER,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  DoctorSlot,
  FetchDoctorSlotsResponse,
} from "../../../domain/models/appointment-module/fetch-doctor-slots";
import { FetchDoctorSlots } from "../../../domain/usages/appointment-module/fetch-doctor-slots";
import { RemoteFetchDoctorSlots } from "../../../data/usecases/appointment-module/remote-fetch-doctor-slots";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Constant } from "../../../constants/constant";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface FetchDoctorSlotsSliceInterface {
  fetchDoctorSlots: Function;
  fetchDoctorSlotsLoading: FetchState;
  doctorSlots: DoctorSlot[];
}

const initialStates = {
  fetchDoctorSlotsLoading: FetchState.DEFAULT,
  doctorSlots: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchDoctorSlotsSlice: StateCreator<
  FetchDoctorSlotsSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchDoctorSlots: async (
    doctor_id: number,
    params: FetchDoctorSlots.Params
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteFetchDoctorSlots = new RemoteFetchDoctorSlots(
      `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_DOCTOR_SLOTS}`,
      axiosHttpClient
    );

    set(() => ({ fetchDoctorSlotsLoading: FetchState.LOADING }));
    let result: FetchDoctorSlotsResponse = await remoteFetchDoctorSlots.fetch(
      doctor_id,
      params
    );

    if (result) {
      if (result.success) {
        set(() => ({
          doctorSlots: result.slots,
          fetchDoctorSlotsLoading: FetchState.SUCCESS,
        }));
      } else {
        set(() => ({ fetchDoctorSlotsLoading: FetchState.ERROR }));
        Swal.fire("Fetching Doctor Slots Failed.", "", "error");
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
