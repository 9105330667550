import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  DOCTOR_SERVICE_API_URL,
  AUTH_HEADER,
  CAPTCHA_HEADER,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { useAppointmentDetailsStore } from "../patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import { RemoteCancelAppointment } from "../../../data/usecases/appointment-module/remote-cancel-appointment";
import { CancelAppointmentResponse } from "../../../domain/models/appointment-module/cancel-appointment-response";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Constant } from "../../../constants/constant";
import moment from "moment";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface cancelAppointmentSliceInterface {
  cancelAppointment: Function;
  cancelAppointmentLoading: FetchState;
}

const initialStates = {
  cancelAppointmentLoading: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const usecancelAppointmentlice: StateCreator<
  cancelAppointmentSliceInterface
> = (set, get) => ({
  ...initialStates,
  cancelAppointment: async (reason: string, appointment_id: number, captacha:string) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token??"" });
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captacha });
    const remoteCancelAppointment = new RemoteCancelAppointment(
      `${DOCTOR_SERVICE_API_URL}${Endpoints.CANCEL_APPOINTMENT}`,
      axiosHttpClient
    );

    const payload = {
      reason,
    };

    set(() => ({ cancelAppointmentLoading: FetchState.LOADING }));
    let result: CancelAppointmentResponse =
      await remoteCancelAppointment.cancel(appointment_id, payload);

    if (result) {
      if (result.success) {
        set(() => ({ cancelAppointmentLoading: FetchState.SUCCESS }));
        useAppointmentDetailsStore
          .getState()
          .fetchAppointments(
            1,
            false,
            useAppointmentDetailsStore.getState().appointmentFilter
          );
        useAppointmentDetailsStore.getState().showAllAppointments &&
          useAppointmentDetailsStore
            .getState()
            .fetchCalendarCount(
              moment(
                useAppointmentDetailsStore.getState().appointmentDate.toString()
              ).format(Constant.YEAR_MONTH_FORMAT),
              useAppointmentDetailsStore.getState().appointmentFilter.status
            );
        useAppointmentDetailsStore
          .getState()
          .fetchAppointmentsById(appointment_id);
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      } else {
        Swal.fire("Appointment Cancellation failed.", "", "error");
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
