import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import { RemoteFetchProgramEnrollments } from "../../../data/usecases/remote-fetch-program-enrollments";
import Endpoints from "../../../domain/endpoints";
import { EnrollmentFilterType } from "../../../domain/models/enrollment-filter.type";
import { EnrollmentType } from "../../../domain/models/enrollment-type";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useFollowUpsStore } from "../followUpStore/followUpsStore";
import { usePersonalDetailStore } from "../patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { Constant } from "../../../constants/constant";
import { FetchProgramEnrollments } from "../../../domain/usages/fetch-program-enrollments";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { Console } from "console";
import { pageRoutes } from "../../../routes";
import { useEnrollmentMainStore } from "./enrollmentMainStore";

interface FetchEnrollmentSliceType {
  enrollmentList: EnrollmentType[];
  fetchEnrollments: Function;
  fetchEnrolmentState: FetchState;
  selectedCard: EnrollmentType;
  reloadPage: Function;
  filter: EnrollmentFilterType;
  handleCardClick: Function;
  currentPage: number;
  lastPage: number;
  isPermissionDenied: boolean;
  totalEnrolmentCount: number;
  resetFilter: Function
}
let initialPayload = {
  q: "",
  startDate: "",
  endDate: "",
};
const initialStates = {
  currentPage: 1,
  lastPage: 1,
  totalEnrolmentCount: 0,
  enrollmentList: [],
  filter: initialPayload as EnrollmentFilterType,
  selectedCard: {} as EnrollmentType,
  fetchEnrolmentState: FetchState.DEFAULT,
  isPermissionDenied:false
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchEnrollmentSlice: StateCreator<FetchEnrollmentSliceType> = (
  set,
  get
) => ({
  ...initialStates,
  fetchEnrollments: async (
    page: number,
    filter: EnrollmentFilterType,
    scrolled: boolean
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteFetchProgramEnrollments: FetchProgramEnrollments =
      new RemoteFetchProgramEnrollments(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_ENROLLMENTS}`,
        axiosHttpClient
      );
    if (!scrolled) {
      set(() => ({ fetchEnrolmentState: FetchState.LOADING, isPermissionDenied:false }));
    }
    try {
      let enrollmentFilter = {
        q: filter.q,
        length: Constant.PAGE_LIMIT,
        start_date: filter.startDate,
        end_date: filter.endDate,
      };
      let result = await remoteFetchProgramEnrollments.fetch(
        page,
        enrollmentFilter
      );
      if (result.success) {
        
        set(() => ({
          totalEnrolmentCount: result.enrolments.total,
          currentPage: result.enrolments.current_page,
          lastPage: result.enrolments.last_page,
          fetchEnrolmentState: FetchState.SUCCESS,
        }));
        if (page === 1) {
          set(() => ({
            selectedCard: { ...result.enrolments.data[0] } as EnrollmentType,
          }));
        }
        if (scrolled) {
          set(() => ({
            enrollmentList: [
              ...get().enrollmentList,
              ...result.enrolments.data,
            ],
          }));
        } else {
          set(() => ({ enrollmentList: result.enrolments.data }));
      
          usePersonalDetailStore
            .getState()
            .getPatient(result.enrolments.data[0].patient_uuid);
          useFollowUpsStore
            .getState()
            .followUpThreadForEnrolmentId(result.enrolments.data[0].id, false);
        }
      } else if (result == 403) {
        set(() => ({ fetchEnrolmentState: FetchState.ERROR, isPermissionDenied:true }));
        loggedInUser.logout();
        
      } else if (result == 404) {
        get().reloadPage();
      } else{
        set(() => ({ fetchEnrolmentState: FetchState.ERROR, isPermissionDenied:true }));
        loggedInUser.logout();
  
      }
    } catch (error) {
      console.log(error, "error")
 
    
      set(() => ({ fetchEnrolmentState: FetchState.ERROR }));
    }
  },
  reloadPage: () => {
    get().fetchEnrollments(1, get().filter, false);
  },

  resetFilter:()=>{
set(()=>({filter: initialPayload as EnrollmentFilterType}))
  },
  handleCardClick: async (index: number) => {
    set(() => ({ selectedCard: get().enrollmentList[index] }));
    usePersonalDetailStore
      .getState()
      .getPatient(get().enrollmentList[index].patient_uuid);
    useFollowUpsStore
      .getState()
      .followUpThreadForEnrolmentId(get().enrollmentList[index].id, false);
  },
});
