import { StateCreator } from "zustand";


import { FetchState } from "../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { COMMON_AUTH_HEADER, FULFILLMENT_SERVICE_API_URL, FULFILLMENT_SERVICE_TOKEN } from "../../base";
import { RemoteGetReadLink } from "../../data/usecases/remote-get-read-link";
import { GetReadLink } from "../../domain/usages/get-read-link";
import Endpoints from "../../domain/endpoints";
import { RemoteGetFulfillmentReadLink } from "../../data/usecases/remote-get-fulifillment-read-link";


interface GetReadLinkSliceInterface {
  getImage: Function;
  fetchImageState: FetchState;
}

const initialStates = {
  fetchImageState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFulfillmentGetReadLinkSlice: StateCreator<GetReadLinkSliceInterface> = (
  set
) => ({
  ...initialStates,
  getImage: async (
    uuid: string,
    storeImage: Function,
    read_url?: string,
    refreshImage?: boolean
  ) => {
    set(() => ({ fetchImageState: FetchState.LOADING }));
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });
    const remoteGetReadLink: GetReadLink = new RemoteGetFulfillmentReadLink(
      `${FULFILLMENT_SERVICE_API_URL}${Endpoints.GET_READ_LINK}`,
      axiosHttpClient
    );
    storeImage("");
    if (read_url && uuid && !refreshImage) {
      storeImage(read_url);
      set(() => ({
        fetchImageState: FetchState.SUCCESS,
      }));
    } else {
      try {
        set(() => ({ fetchImageState: FetchState.LOADING }));
        if (uuid) {
          let result = await remoteGetReadLink.get(uuid);
          if (result.status) {
            storeImage(result.data?.url);
            set(() => ({
              fetchImageState: FetchState.SUCCESS,
            }));
            return result
          }
        }
      } catch (err) {
        set(() => ({ fetchImageState: FetchState.ERROR }));
      }
    }
  },
});
