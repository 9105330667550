import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AppointmentType,
  FetchAppointmentsResponse,
} from "../../../domain/models/appointment-module/fetch-appointment-response";
import { RemoteFetchAppointments } from "../../../data/usecases/appointment-module/remote-fetch-appointments";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Constant } from "../../../constants/constant";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

type AppointmentFilter = {
  requester_uuid?: number;
  date?: string;
  status?: string;
};

interface FetchAppointmentsSliceInterface {
  fetchAppointments: Function;
  fetchAppointmentsLoading: FetchState;
  appointmentData: AppointmentType[];
  page: number;
  totalAppointments: number;
  appointmentFilter: AppointmentFilter;
}

const initialStates = {
  fetchAppointmentsLoading: FetchState.DEFAULT,
  appointmentData: [],
  page: 1,
  totalAppointments: 0,
  appointmentFilter: {} as AppointmentFilter,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchAppointmentsSlice: StateCreator<
  FetchAppointmentsSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchAppointments: async (
    page: number,
    scrolled: boolean,
    filter: AppointmentFilter
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteFetchAppointments = new RemoteFetchAppointments(
      `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_APPOINTMENTS}`,
      axiosHttpClient
    );

    const payload = {
      page,
      ...filter,
    };

    if (!scrolled) {
      set(() => ({ fetchAppointmentsLoading: FetchState.LOADING }));
    }
    let result: FetchAppointmentsResponse = await remoteFetchAppointments.fetch(
      payload
    );
    if (result) {
      if (result.success) {
        if (scrolled) {
          set(() => ({
            appointmentData: [...get().appointmentData, ...result.appointments],
            page: page,
            totalAppointments: result.total,
          }));
        } else {
          set(() => ({
            appointmentData: result.appointments,
            totalAppointments: result.total,
            page: page,
          }));
        }
        set(() => ({
          fetchAppointmentsLoading: FetchState.SUCCESS,
          appointmentFilter: filter,
        }));
      } else {
        Swal.fire("Fetching appointments failed.", "", "error");
        set(() => ({ fetchAppointmentsLoading: FetchState.ERROR }));
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
