import React, { useEffect, useState } from "react";
import { useDocumentStore } from "../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import restrictedContent from "../../../assets/images/restricted-content.jpg";
import { Box, Button, Modal } from "@mui/material";

type Props = {
  uuid: string;
};

const ImageComponent: React.FC<Props> = ({ uuid }) => {
  const { getProgramDocs, url } = useDocumentStore();
  const { isBlurred } = useEnrollmentMainStore();
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState<number>(1);

  const handleZoomIn = () => setScale((prevScale) => prevScale + 0.1);
  const handleZoomOut = () =>
    setScale((prevScale) => Math.max(0.5, prevScale - 0.1));
  const handleReset = () => setScale(1);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleReset(); // Reset zoom when closing the modal
  };

  useEffect(() => {
    if (uuid) {
      getProgramDocs(uuid);
    }
  }, [uuid]);

  const findUrl = (imageUuid: string) => {
    const imageURL = url.find((item) => item.uuid === imageUuid);
    return imageURL?.url || restrictedContent;
  };

  return (
    <Box position="relative" width="280px" height="300px">
      <img
        src={isBlurred ? restrictedContent : findUrl(uuid)}
        alt="Preview"
        height="100%"
        width="100%"
        style={{ objectFit: "cover", cursor: "pointer" }}
        onClick={handleOpen}
      />
      <Button
        className="hover-button"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0,
          transition: "opacity 0.3s",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            opacity: 1,
          },
        }}
        onClick={handleOpen}
      >
        View Preview
      </Button>

      {/* Modal for Preview */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "600px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <img
            src={findUrl(uuid)}
            alt="Zoomed Preview"
            style={{
              transform: `scale(${scale})`,
              transition: "transform 0.3s ease",
              maxHeight: "80vh",
              maxWidth: "100%",
            }}
          />
          <Box mt={2} display="flex" gap={2}>
            <Button onClick={handleZoomIn} variant="contained">
              Zoom In
            </Button>
            <Button onClick={handleZoomOut} variant="contained">
              Zoom Out
            </Button>
            <Button onClick={handleReset} variant="outlined">
              Reset
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageComponent;
