import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  DOCTOR_SERVICE_API_URL,
  AUTH_HEADER,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { RemoteFetchDoctorsForAppointment } from "../../../data/usecases/appointment-module/remote-fetch-doctor-for-appointment";
import {
  FetchUsersResponse,
  UserType,
} from "../../../domain/models/appointment-module/fetch-users-response";
import { FetchDoctorsForAppointment } from "../../../domain/usages/appointment-module/fetch-doctor";
import { Constant } from "../../../constants/constant";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface FetchDoctorsForAppointmentSliceInterface {
  fetchDoctorsForAppointment: Function;
  fetchDoctorsForAppointmentLoading: FetchState;
  doctorsForAppointmentData: UserType[];
}

const initialStates = {
  fetchDoctorsForAppointmentLoading: FetchState.DEFAULT,
  doctorsForAppointmentData: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchDoctorsForAppointmentSlice: StateCreator<
  FetchDoctorsForAppointmentSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchDoctorsForAppointment: async (
    params: FetchDoctorsForAppointment.Params
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteFetchDoctorsForAppointment =
      new RemoteFetchDoctorsForAppointment(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_DOCTOR_FOR_APPOINTMENT}`,
        axiosHttpClient
      );

    set(() => ({ fetchDoctorsForAppointmentLoading: FetchState.LOADING }));
    let result: FetchUsersResponse =
      await remoteFetchDoctorsForAppointment.fetch(params);

    if (result) {
      if (result.success) {
        set(() => ({ doctorsForAppointmentData: result.users }));
        set(() => ({ fetchDoctorsForAppointmentLoading: FetchState.SUCCESS }));
      } else {
        Swal.fire("Fetching Doctors failed.", "", "error");
      }
    } else {
      set(() => ({ fetchDoctorsForAppointmentLoading: FetchState.ERROR }));
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
