import { StateCreator } from "zustand";
import { FetchState } from "../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  DOCTOR_SERVICE_API_URL,
} from "../../base";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Endpoints from "../../domain/endpoints";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";
import { RemoteFetchDoctors } from "../../data/usecases/doctor-service/remote-fetch-doctors";
import { FetchDoctors } from "../../domain/usages/doctor-service/fetch-doctors";
import { Doctor } from "../../domain/models/doctor";

interface FetchDoctorsSliceInterface {
  fetchDoctors: Function;
  fetchDoctorState: FetchState;
  doctorList: Doctor[]

}

const initialStates = {
    fetchDoctorState: FetchState.DEFAULT,
    doctorList:[]
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const usefetchDoctorsSlice: StateCreator<
FetchDoctorsSliceInterface
> = (set) => ({
  ...initialStates,
  fetchDoctors: async (params: FetchDoctors.Params, captcha: string) => {
    set(() => ({ fetchDoctorState: FetchState.LOADING }));

    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token ??"" });
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captcha??"" });
   
    const remoteFetchDoctors = new RemoteFetchDoctors(
      `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_DOCTORS}`,
      axiosHttpClient
    );
    try {
        
        let result = await remoteFetchDoctors.fetch(params);
        if (result.success) {
          set(() => ({
            doctorList: result.doctors,
            fetchDoctorState: FetchState.SUCCESS,
          }));

          return result.data.url;
        } else {
          set(() => ({
            fetchDoctorState: FetchState.ERROR,
          }));
          return "";
        }
      
    } catch (err) {
      set(() => ({ fetchDoctorState: FetchState.ERROR }));
      return "";
    }
  },
});
