import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  CardMedia,
  Typography,
  Button,
  Stack,
  Container,
  CircularProgress,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Document, Page, pdfjs } from "react-pdf";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import pdfIcon from "../../assets/images/pdfIcon.png";
import axios from "axios";
import { toTitleCase } from "../../domain/Utils";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

interface Image {
  uuid: string;
  url: string;
  name: string;
}

const ImageGallery: React.FC<{ images: Image[] }> = ({ images }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isPdf, setIsPdf] = useState(false);
  const [isPdfFiles, setIsPdfFiles] = useState<
    { uuid: string; isPdf: boolean }[]
  >([]);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [scale, setScale] = React.useState<number>(1.3);
  const [imageErrors, setImageErrors] = useState<Set<string>>(new Set());

  const handleZoomIn = () => setScale((prevScale) => prevScale + 0.1);
  const handleZoomOut = () =>
    setScale((prevScale) => Math.max(0.1, prevScale - 0.1));

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handleOpen = (image: string, index: number) => {
    setSelectedImage(image);
    setIsPdf(isPdfFiles[index].isPdf);
    setOpen(true);
  };

  const handleImageError = useCallback((url: string) => {
    setImageErrors((prevErrors) => new Set(prevErrors.add(url)));
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const changePage = (offset: number) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  useEffect(() => {
    const checkForPDF = async (url: string): Promise<string> => {
      try {
        const response = await axios.get(url);
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("image/")) return "image";
        if (contentType === "application/pdf") return "pdf";
        return "unknown";
      } catch (error) {
        console.error("Error fetching URL:", error);
        return "error";
      }
    };

    const updatePdfStatus = async () => {
      const pdfChecks = await Promise.all(
        images.map(async (image) => {
          const fileType = await checkForPDF(image.url);
          return { uuid: image.uuid, isPdf: fileType === "pdf" };
        })
      );

      // Filter out duplicate UUIDs
      setIsPdfFiles((prevFiles) => {
        const newFiles = pdfChecks.filter(
          (newFile) =>
            !prevFiles.some((prevFile) => prevFile.uuid === newFile.uuid)
        );
        return [...prevFiles, ...newFiles];
      });
    };

    updatePdfStatus();
  }, [images]);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={scrollLeft} aria-label="Scroll Left">
          <ChevronLeftIcon />
        </IconButton>
        <Box
          ref={scrollRef}
          sx={{
            display: "flex",
            overflowX: "auto",
            whiteSpace: "nowrap",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                width: 150,
                height: 180,
                margin: 1,
                display: "inline-block",
                "&:hover .hover-button": { opacity: 1 },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isPdfFiles.length > 0 &&
              isPdfFiles[index] &&
              isPdfFiles[index].isPdf ? (
                <img
                  src={pdfIcon}
                  style={{ cursor: "pointer", height: "150px", width: "150px" }}
                  onClick={() => handleOpen(image.url, index)}
                  aria-label="PDF icon"
                />
              ) : imageErrors.has(image.url) ? (
                <ImageNotSupportedIcon
                  sx={{
                    fontSize: 100,
                    color: "text.secondary",
                    cursor: "pointer",
                    height: "150px",
                    width: "150px",
                  }}
                  onClick={() => handleOpen(image.url, index)}
                  aria-label="Image not supported"
                />
              ) : (
                <CardMedia
                  component="img"
                  image={image.url}
                  alt={image.name}
                  sx={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                    cursor: "pointer",
                    display: imageErrors.has(image.url) ? "none" : "block",
                  }}
                  onClick={() => handleOpen(image.url, index)}
                  onError={() => handleImageError(image.url)}
                />
              )}
              <Typography variant="caption" align="center">
                {toTitleCase(image.name)}
              </Typography>
              <Button
                className="hover-button"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  opacity: 0,
                  transition: "opacity 0.3s",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  color: "white",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)" },
                }}
                onClick={() => handleOpen(image.url, index)}
              >
                View Preview
              </Button>
            </Box>
          ))}
        </Box>
        <IconButton onClick={scrollRight} aria-label="Scroll Right">
          <ChevronRightIcon />
        </IconButton>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent>
          {isPdf ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box
                  border={"1px solid"}
                  borderRadius={"30%"}
                  color={"primary.main"}
                  bgcolor={"primary.main"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <IconButton
                    size="small"
                    onClick={handleZoomIn}
                    aria-label="Zoom In"
                  >
                    <ZoomInIcon sx={{ color: "white" }} />
                  </IconButton>
                </Box>
                <Box
                  border={"1px solid"}
                  borderRadius={"30%"}
                  color={"primary.main"}
                  bgcolor={"primary.main"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <IconButton
                    size="small"
                    onClick={handleZoomOut}
                    aria-label="Zoom Out"
                  >
                    <ZoomOutIcon sx={{ color: "white" }} />
                  </IconButton>
                </Box>
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"row"}
                  gap={2}
                >
                  <Typography>
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                  {numPages && (
                    <Button
                      size="small"
                      variant="contained"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </Button>
                  )}
                </Stack>
              </Box>
              <Container
                sx={{
                  overflowY: "auto",
                  flexDirection: "row",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60vh",
                  border: "1px solid",
                }}
              >
                <Document
                  file={selectedImage}
                  loading={
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      height={"60vh"}
                    >
                      <CircularProgress />
                    </Stack>
                  }
                  onEnded={() => <Typography>End of file</Typography>}
                  error={
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginTop={10}
                      gap={1}
                    >
                      <Typography variant="subtitle2" fontWeight={660}>
                        Failed to Load PDF
                      </Typography>
                    </Stack>
                  }
                  noData={
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginTop={10}
                    >
                      No Records Found
                    </Stack>
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} scale={scale} />
                </Document>
              </Container>
            </>
          ) : (
            <img
              src={selectedImage || ""}
              alt="Selected"
              style={{ width: "100%", height: "auto", objectFit: "contain" }} // Fixed size and maintain aspect ratio
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ImageGallery;
