import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { FetchMasters } from "../../domain/usages/fetch-masters";

export class RemoteFetchMasters implements FetchMasters {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(id: string, params?: FetchMasters.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", id),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: false,
      authHeadersViaCaptcha: true
    });
    if (httpResponse.status == 200) {
      return httpResponse.data;
    }
  }
}
