import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  DOCTOR_SERVICE_API_URL,
  CAPTCHA_HEADER,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AppointmentType,
  FetchAppointmentByIdResponse,
} from "../../../domain/models/appointment-module/fetch-appointment-response";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Constant } from "../../../constants/constant";
import { RemoteFetchAppointmentById } from "../../../data/usecases/appointment-module/remote-get-appointment-by-id";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface FetchAppointmentsByIdSliceInterface {
  fetchAppointmentsById: Function;
  fetchAppointmentsByIdLoading: FetchState;
  appointmentDetails: AppointmentType;
}

const initialStates = {
  fetchAppointmentsByIdLoading: FetchState.DEFAULT,
  appointmentDetails: {} as AppointmentType,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchAppointmentByIdSlice: StateCreator<
  FetchAppointmentsByIdSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchAppointmentsById: async (appointee_id: number, captacha: string) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token ??"" });
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captacha });
    const remoteFetchAppointmentById = new RemoteFetchAppointmentById(
      `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_APPOINTMENT_BY_ID}`,
      axiosHttpClient
    );

    set(() => ({ fetchAppointmentsByIdLoading: FetchState.LOADING }));

    let result: FetchAppointmentByIdResponse =
      await remoteFetchAppointmentById.get(appointee_id);
    if (result) {
      if (result.success) {
        set(() => ({
          appointmentDetails: result.appointment,
          fetchAppointmentsByIdLoading: FetchState.SUCCESS,
        }));
      } else {
        Swal.fire("Fetching appointments failed.", "", "error");
        set(() => ({ fetchAppointmentsByIdLoading: FetchState.ERROR }));
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
