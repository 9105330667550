import { StateCreator } from "zustand";
import { ACCESS_TIME_KEY, ADMIN_SECRET_KEY } from "../../../base";
import dayjs from "dayjs";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";

interface BlurTextSliceType {
  handleTextBlur: Function;
  isBlurred: boolean;
  password: string;
  error: string;
  resetBlur: Function;
  lastUnBlurTime: string|null;
  countdownTime: string;
  handleCountdowntime: Function;

}

const initialStates = {
  password: "",
  isBlurred: true,
  error: "",
  lastUnBlurTime: null,
  countdownTime:"00:00:00"
};

const storage = LocalJsonStorage.getInstance();

export const useBlurTextSlice: StateCreator<BlurTextSliceType> = (set, get) => ({
  ...initialStates,
  handleTextBlur: async (inputPassword: string) => {
   
    if (inputPassword == ADMIN_SECRET_KEY) {
      const loggedInUser = new LocalLoggedInUser(storage);
      loggedInUser.setAppAccessTime(dayjs().toISOString());
      set(() => ({ isBlurred: false, error: "" }));
      get().resetBlur()
    } else {
      set(() => ({
        isBlurred: true,
        error:
          "Invalid password. Please ensure your password is correct and try again.",
      }));
    }
  },
  handleCountdowntime:()=>{
    if (get().lastUnBlurTime) {
      const timeRemaining = moment
        .duration(6, "hours")
        .subtract(moment().diff(moment(get().lastUnBlurTime), "milliseconds"));

      if (timeRemaining.asMilliseconds() <= 0) {
        set(() => ({ countdownTime: "00:00:00" }));
        get().resetBlur();
        return;
      }

      const hours = String(timeRemaining.hours()).padStart(2, "0");
      const minutes = String(timeRemaining.minutes()).padStart(2, "0");
      const seconds = String(timeRemaining.seconds()).padStart(2, "0");
      set(() => ({ countdownTime: `${hours}:${minutes}:${seconds}` }));


      if (timeRemaining.asSeconds() <= 0) {
        get().resetBlur();
      }
    }


   
  },




  resetBlur: () => {
    const lastUnblurTimestamp = secureLocalStorage.getItem(ACCESS_TIME_KEY);
  
    if (typeof lastUnblurTimestamp === "string" && lastUnblurTimestamp) {
      set(() => ({ lastUnBlurTime: lastUnblurTimestamp}));
      const lastUnblurTime = dayjs(lastUnblurTimestamp);
      if (dayjs().diff(lastUnblurTime, "hour") < 6) {

        set(() => ({ isBlurred: false, error: "" }));
        get().handleCountdowntime()
      } else {
        set(() => ({ isBlurred: true, error: "", countdownTime: "00:00:00", }));
      }
    } else {
      set(() => ({ isBlurred: true, error: "", countdownTime: "00:00:00", lastUnBlurTime: null }));
    }
  },
});
