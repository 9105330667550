import {
  Card,
  CardHeader,
  Avatar,
  Stack,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { usePersonalDetailStore } from "../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useEnrollmentMainStore } from "../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import EditIcon from "@mui/icons-material/Edit";
import { toTitleCase } from "../../../../domain/Utils";
import ChangePatientStatusModal from "../../modals/ChangePatientStatusModal";

interface PatientBasicDetailsInterface {
  activeTab: string;
}

const PatientBasicDetails: React.FC<PatientBasicDetailsInterface> = ({
  activeTab,
}) => {
  const enrollmentStatusData = [
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Closed",
      value: "closed",
    },
    {
      name: "Discontinued",
      value: "discontinued",
    },
    {
      name: "Cancelled",
      value: "cancelled",
    },
    {
      name: "Non Contactable",
      value: "non_contactable",
    },
  ];

  const {
    selectedPatientPersonalDetails,
    updatePatientStatus,
    updatePatientStatusLoading,
  } = usePersonalDetailStore();
  const { sendConsentSMS, selectedCard, isBlurred } = useEnrollmentMainStore();
  const [currentEnrollmentStatus, setCurrentEnrollmentStatus] =
    useState<String>("");
  const [showEditEnrollmentStatus, setShowEditEnrollmentStatus] =
    useState<boolean>(false);
  const [openChangePatientStatusModal, setOpenChangePatientStatusModal] =
    useState<boolean>(false);

  const handleSendConsentSMS = () => {
    sendConsentSMS(selectedCard.id);
  };

  useEffect(() => {
    setCurrentEnrollmentStatus(selectedCard.status);
  }, []);

  const handleUpdateStatus = (reason: string, date: string) => {
    let payload = {
      status: currentEnrollmentStatus,
      comment: reason,
      closed_date: date,
    };
    updatePatientStatus(selectedCard.id, payload, () => {
      setOpenChangePatientStatusModal(false);
    });
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: " 1px solid #D0CFCF",
          borderRadius: " 12px",
          marginTop: "14px",
          cursor: "pointer",
          paddingRight: "26px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{
                  bgcolor: "#6750A4",
                }}
                className={isBlurred ? "blurred-text" : ""}
              >
                {selectedPatientPersonalDetails?.fullName &&
                  selectedPatientPersonalDetails?.fullName[0]}
              </Avatar>
            }
            title={
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
                width={"100%"}
              >
                <Typography className={isBlurred ? "blurred-text" : ""}>
                  {selectedPatientPersonalDetails?.fullName}
                </Typography>
                {selectedCard.consent_acknowledgement != 1 && !isBlurred && (
                  <Tooltip title={"click to send consent SMS"}>
                    <Box border={"1px solid #3F51B7"} borderRadius={"50%"}>
                      <IconButton
                        color="secondary"
                        onClick={handleSendConsentSMS}
                      >
                        <PendingActionsIcon color="secondary" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            }
            titleTypographyProps={{
              fontFamily: "Segoe UI",
              fontWeight: 500,
              fontSize: "16px",
            }}
            // subheader={selectedPatientPersonalDetails?.mobile}
            subheaderTypographyProps={{
              fontFamily: "Segoe UI",
            }}
          />
          {!showEditEnrollmentStatus && selectedCard.status && (
            <Stack alignItems={"center"} flexDirection={"row"} gap={1}>
              <Typography fontWeight={660} variant="subtitle2">
                Current Status: {toTitleCase(selectedCard.status)}
              </Typography>
              {!isBlurred && (
                <IconButton onClick={() => setShowEditEnrollmentStatus(true)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          )}

          {(showEditEnrollmentStatus || selectedCard.status == null) && (
            <TextField
              size="small"
              select
              label="Current Status"
              sx={{ width: "35%" }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: currentEnrollmentStatus &&
                  currentEnrollmentStatus != selectedCard.status && (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color={"secondary"}
                        size={"small"}
                        onClick={() => {
                          setOpenChangePatientStatusModal(true);
                        }}
                      >
                        <Typography variant="subtitle2" color="white">
                          Update
                        </Typography>
                      </Button>
                    </InputAdornment>
                  ),
              }}
              value={currentEnrollmentStatus}
              onChange={(e) => setCurrentEnrollmentStatus(e.target.value)}
            >
              {enrollmentStatusData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Stack>
      </Card>
      {openChangePatientStatusModal && (
        <ChangePatientStatusModal
          openChangePatientStatusModal={openChangePatientStatusModal}
          handleCloseConfirmationModal={() => {
            setOpenChangePatientStatusModal(false);
          }}
          handleSuccessCallback={handleUpdateStatus}
          message="Are you sure you want to change status of this patient?  if yes, enter date and reason"
          loading={updatePatientStatusLoading}
        />
      )}
    </>
  );
};
export default PatientBasicDetails;
